import React from 'react'
import './index.css'
import FaqCard from '../../components/FaqCard'
import SectionHeader from '../../components/SectionHeader'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const FaqPage = () => {
  const { width } = useWindowDimensions()

  const SHOW_ANSWERS = width > 600
  return (
    <div id="faq-page">
      <SectionHeader title="Frequently Asked Questions" />
      <FaqCard
        question="Where is the event located?"
        displayAnswer={SHOW_ANSWERS}
      >
        <p>
          The ceremony and reception will be held at East Canyon Resort in
          Morgan, UT.
        </p>
      </FaqCard>
      <FaqCard question="How do I get there?" displayAnswer={SHOW_ANSWERS}>
        <p>
          During the Summer and Fall months you can take Highway 65 to the
          resort.
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d193442.00605836982!2d-111.95227992888663!3d40.75033678922766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x8752f38b67d7f4e9%3A0x6f1f4326f5594819!2sSalt%20Lake%20City%20International%20Airport%20(SLC)%2C%20W%20Terminal%20Dr%2C%20Salt%20Lake%20City%2C%20UT%2084122!3m2!1d40.787363299999996!2d-111.9853706!4m5!1s0x8752f51bffffffff%3A0x1593ef7dd41414a9!2sEast%20Canyon%20Resort%2C%20Utah%2065%2C%20Morgan%2C%20UT!3m2!1d40.870867!2d-111.584526!5e0!3m2!1sen!2sus!4v1694100567768!5m2!1sen!2sus"
          className="iframe"
          loading="eager"
          title="East Canyon Resort"
        ></iframe>
        <p>
          <strong>NOTE:</strong> If there is snow, Highway 65 may be closed by
          UDOT about one mile south of the Resort preventing access to the
          resort from I-80 over Big Mountain Pass. If this is the case, the
          Resort can be accessed through Henefer or Morgan. See the "winter
          access" page for details:{' '}
          <a className="link" href="https://www.eastcanyon.com/directions">
            https://www.eastcanyon.com/directions
          </a>
        </p>
      </FaqCard>
      <FaqCard question="What are the hours?" displayAnswer={SHOW_ANSWERS}>
        <p>
          The event will begin at 3PM MST and end at 8PM MST. Individual time
          details for the ceremony and reception will be released closer to the
          event.
        </p>
      </FaqCard>
      <FaqCard question="How can I RSVP?" displayAnswer={SHOW_ANSWERS}>
        <p>
          Filling out the form at the bottom of the home page is the preferred
          way to RSVP. Doing so will let us know you can make it, as well as
          send a calendar reminder to the email you use. If you'd rather RSVP
          another way you can text either Alisia or Scott to let us know you're
          coming, or email us at{' '}
          <a className="link" href="mailto:rsvp@scottandalisia.com">
            rsvp@scottandalisia.com
          </a>
          .
        </p>
      </FaqCard>
      <FaqCard question="When should I RSVP by?" displayAnswer={SHOW_ANSWERS}>
        <p>Please RSVP by September 15th, 2023.</p>
      </FaqCard>
      <FaqCard question="Will there be alcohol?" displayAnswer={SHOW_ANSWERS}>
        <p>
          The event will be Bring Your Own Booze. If you plan on bringing
          alcohol please make sure it is labeled with your name so we know whose
          it is. Due to Utah state liquor laws all alcohol must be brought to
          the bartender upon arrival (you can bring it to our condo), and served
          only by the bartender on-site.
        </p>
      </FaqCard>
      <FaqCard question="Will there be food?" displayAnswer={SHOW_ANSWERS}>
        <p>
          Yes, food will be provided during the reception. The main dish will be
          beef chilli in a bread bowl, with a side of Pasta Salad and Waldorf
          Salad.
        </p>
      </FaqCard>
      <FaqCard question="Is there a dress code?" displayAnswer={SHOW_ANSWERS}>
        <p>Please wear formal/semi formal attire.</p>
      </FaqCard>
      <FaqCard question="Can I bring my dog?" displayAnswer={SHOW_ANSWERS}>
        <p>
          No, with the exception of service animals, pets are not allowed at the
          venue.
        </p>
      </FaqCard>
      <FaqCard question="Can I bring a plus one?" displayAnswer={SHOW_ANSWERS}>
        <p>
          Yes! Anyone coming solo is welcome to bring a plus one. Please just
          let us know who is coming when filling out the RSVP form so we can
          have an accurate head-count for food.
        </p>
      </FaqCard>
    </div>
  )
}

export default FaqPage
